.paleta_colores{
    margin-top: 50px;
    width: 90%;
    

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .recaudro{
        width: 40px;
        height: 55px;
        margin: 10px;
    }

    .recaudro:hover{
        cursor: pointer;
    }    
}