.div-dibujo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .imagenes_bethoven{
        margin-top: 10px;
        width: 60%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        

        // svg {   
        //     width: 581px;
        //     height: 581px;         
        //     margin-left: 10%;
        // }
    }
    .botones{
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .btn{
            img{
                width: 200px;
            }
        }
    
        .btn:hover{
            cursor: pointer;
        }
    }
}
